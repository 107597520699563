import { Link } from "react-router-dom";
import moment from "moment";

export default function PostRow({ post = null }) {
  return (
    <div>
      {post && (
        <div className="post-tease flex gap-4 mb-4">
          <Link to={`/${post.slug}`}>
            <div
              style={{
                backgroundImage: `url(${
                  post.type === "post"
                    ? post._embedded["wp:featuredmedia"][0]?.source_url
                    : null
                })`,
              }}
              className="w-24 h-24 aspect-square bg-gray-200 bg-opacity-30 flex items-end text-center bg-center bg-cover justify-center text-white"
            ></div>
          </Link>
          <div className="flex-1 flex-grow">
            <Link to={`/${post.slug}`}>
              <h2
                className="entry-title list-heading mb-4"
                dangerouslySetInnerHTML={{ __html: post.title.rendered }}
              />
            </Link>
            {post._embedded.author[0]?.name && (
              <p className="text-xs font-light mb-2">Posted by: {post._embedded.author[0]?.name}</p>
            )}
            {post.date && (
              <p className="text-xs font-light mb-2">Posted At: {moment(post.date).format("dddd, Do MMMM YYYY, h:mm:ss a")}</p>
            )}
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
