import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { PageWrapperClass, WpPages } from "../utils/Constants";
import PostPageContent from "../components/PostPageContent";
import Post from "../components/Post";
import Page from "../components/Page";
import "../styles/wp.css";

export default function Slug() {
  const { slug } = useParams();
  const [posts, setPosts] = useState([]);
  const [postType, setPostType] = useState(null);
  useEffect(() => {
    async function loadPosts() {
      const pageExists = WpPages.filter((item) => item === slug); 
      if (pageExists.length > 0) {
         setPostType('page');
         return
      } 
      setPostType('post')
    }
    console.log("Got here");
    loadPosts();
  }, [slug]);

  return postType !== null && postType === "post" ? (
    <Post slug={slug} />
  ) : postType !== null && postType === "page" ? (
    <Page slug={slug} />
  ) : (
    <div></div>
  );
}
