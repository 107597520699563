import { useState, useEffect } from "react";
import PostSquare from "../PostSquare";
import Slider from "react-slick";
import LoadingRow from "../LoadingRow";

export default function PostCarousel({ postType, title, filter = "" }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const settingsEpisodes = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 8,
    slidesToScroll: 4,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    async function loadPosts() {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/${postType}?_embed&${filter}`
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      const posts = await response.json();
      setPosts(posts);
      setLoading(false);
    }
    if (posts.length === 0 && loading === false) loadPosts();
  }, []);
  return (
    <div className="mb-8 w-full">
      <h2 className="section-heading mb-4">{title}</h2>
      <div>
        {loading === false && (
          <div className="-mx-2">
            <Slider {...settingsEpisodes}>
              {posts.length > 0 &&
                posts.map((post, index) => (
                  <PostSquare post={post} key={index} />
                ))}
            </Slider>
          </div>
        )}
        {loading === true && <LoadingRow />}
      </div>
    </div>
  );
}
