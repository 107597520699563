import { useState, useEffect, useContext } from "react";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { API, STATION_SLUG } from "../utils/Constants";
import { useParams } from "react-router-dom";
import { buildEpisodeTrack } from "../utils/Functions";
import { RadioContext } from "../Context";
import EpisodePage from "../components/EpisodePage";
import { PageWrapperClass } from "../utils/Constants";
import Banner from "../components/Banner";

export default function Episode() {
  const { setTrack, track, playing, buffering, playAudio } =
    useContext(RadioContext);
  const { timestamp } = useParams();
  const [loading, setLoading] = useState(false);
  const [episode, setEpisode] = useState(null);
  const [thisTrack, setThisTrack] = useState(null);

  useEffect(() => {
    const getFront = async () => {
      setLoading(true);
      var req = await fetch(
        `${API}/public/station/${STATION_SLUG}/episode/${timestamp}`
      );
      const results = await req.json();
      console.log(results);
      if (results.data) {
        //   if (results.data.episodes.length > 0) {
        //     setOnAir(results.data.episodes[0]);
        //   }
        setEpisode(results.data);
        const t = buildEpisodeTrack({ episode: results.data });
        setThisTrack(t);
        console.log(t);
        //   setSections(results.data.sections);
      }
      setLoading(false);
    };
    getFront();
  }, [timestamp, setLoading]);
  return (
    <Layout
      article={
        thisTrack
          ? { title: `Episode - ${thisTrack.title}` }
          : { title: "Loading Episode" }
      }
    >
      <div className={PageWrapperClass}>
        {loading && <Loading />}
        {episode && <h6 className="xsmall-heading font-bold mb-2">EPISODE:</h6>}

        {episode && episode.title ? (
          <h1 className="page-heading mb-4 lg:mb-4">{episode.title}</h1>
        ) : episode ? (
          <Link to={`/program/${episode.program.slug}`}>
            <h1 className="page-heading  mb-4 lg:mb-4">
              {episode.program.name}
            </h1>
          </Link>
        ) : (
          ""
        )}
        {episode && episode.readable && (
          <h6 className="small-heading mb-0">{episode.readable}</h6>
        )}
        {episode && episode.title && episode.program && (
          <Link to={`/program/${episode.program.slug}`}>
            <h2 className="page-subheading mb-9">
              Program: {episode.program.name}
            </h2>
          </Link>
        )}
        {episode && <EpisodePage episode={episode} />}

        {loading === false && episode && <Banner />}
      </div>
    </Layout>
  );
}
