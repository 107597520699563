import Slider from "react-slick";

const elements = [0, 1, 2, 3, 4, 5, 7, 8, 9, 10];

export default function LoadingRow() {
  const settingsEpisodes = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows:false,
    // slidesToShow: 8,
    slidesToScroll: 4,
    variableWidth: true,
    responsive: [
      {
        arrows:false,
        dots: false,
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full">
      <div className="-mx-2">
        <Slider {...settingsEpisodes}>
          {elements.map((item) => (
            <div className="episode px-2" key={item}>
              <div className="aspect-square bg-gray-700 bg-opacity-30 flex items-end text-center    text-white w-full animate-pulse">
                <div className="h-full aspect-square width-full bg-gray-700 rounded"></div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
