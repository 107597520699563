import { useContext } from "react";
import { Link } from "react-router-dom";
import { RadioContext } from "../../Context";
import { PauseIcon, PlayIcon } from "@heroicons/react/solid";
import PlayButton from "../PlayButton";

export default function Feature({ episode }) {
  const { setTrack, track, liveStream, playing, buffering, playAudio } =
    useContext(RadioContext);
  const thisTrack = {
    ...liveStream,
    title: `ON AIR NOW: ${episode.program.name}`,
    link: `/episode/${episode.timestamp}`,
  };
  return (
    <div
      className="w-full   text-white bg-gray-900 bg-center bg-cover relative mb-4 featured  shadow-xl rounded-xl"
      style={{
        backgroundImage: `url(${
          episode.image && episode.image.webp
            ? episode.image.webp.banner
            : episode.image && episode.image.url
            ? episode.image.url
            : episode.program &&
              episode.program.image &&
              episode.program.image.webp
            ? episode.program.image.webp.banner
            : episode.program && episode.program.image
            ? episode.program.image.url
            : ""
        })`,
      }}
    >
      <Link to={`/episode/${episode.timestamp}`} className=" ">
        <div className="  absolute  shadow-xl rounded-xl left-0 pt-12 bottom-0 right-0 px-2 pb-2 md:px-4 md:pb-4 lg:px-6 lg:pb-6 xl:px-12 xl:pb-12 bg-gradient-to-t from-gray-900">
          {thisTrack && (
            <PlayButton forceDisplay={true} thisTrack={thisTrack} />
          )}
          <h4 className="section-heading">ON AIR NOW:</h4>
          <h2 className="mega-heading">{episode.program.name}</h2>
          {episode.title && (
            <h4 className="small-heading mt-1">{episode.title}</h4>
          )}
        </div>
      </Link>
    </div>
  );
}
