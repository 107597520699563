import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { PageWrapperClass } from "../utils/Constants";
import PostRow from "../components/PostRow";
import Loading from "../components/Loading";
import "../styles/wp.css";
import Banner from "../components/Banner";

export default function Posts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function loadPosts() {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/posts?_embed`
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      setLoading(false);
      const posts = await response.json();
      setPosts(posts);
    }

    loadPosts();
  }, []);
  return (
    <Layout
      route="posts"
      pathname="/posts"
      article={{
        title: "Latest News",
        description: "The latest news from eastside radio.",
      }}
    >
      <div className={PageWrapperClass}>
        <h1 className="page-heading mb-12">LATEST BLOG POSTS</h1>
        {loading === true && <Loading />}
        {posts.map((post, index) => (
          <PostRow post={post} key={index} />
        ))}
        {loading === false && <Banner type={"site"} />}
      </div>
    </Layout>
  );
}
