import { useContext, useState, useEffect } from "react";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import SlotRow from "../components/SlotRow";
import { RadioContext } from "../Context";
import { PageWrapperClass } from "../utils/Constants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";

const dayArray = [0, 1, 2, 3, 4, 5, 6];

export default function TimeTable() {
  const { station, stationLoading } = useContext(RadioContext);
  const [episodes, setEpisodes] = useState();
  const [filter, setFilter] = useState("");
  const [weekdays, setWeekdays] = useState([]);
  const d = new Date(new Date().setHours(0, 0, 0, 0));

  useEffect(() => {
    if (station) {
      setEpisodes(station.episode);
      console.log("pw", station.episodes);
    }
  }, [station]);

  useEffect(() => {
    if (weekdays.length === 0) {
      const w = dayArray.map((item, i) => {
        // const d = new Date(new Date().setHours(0, 0, 0, 0));
        // const e = new Date(new Date().setHours(0, 0, 0, 0));
        const start = moment(d);
        const st = start.add(i, "days");
        return {
          d: d,
          start: st.unix(),
          day: st.format("dddd"),
          end: st.unix() + (24 * 60 * 60 - 1),
          today: i === 0 ? true : false,
          readable: st.format("dddd Do MMMM"),
        };
      });
      setWeekdays(w);
      console.log("weekdays", w);
    }
  });

  return (
    <Layout
      pathname={`/timetable`}
      article={{ title: "Our Timetable" }}
      route={"programs"}
    >
      <div className={PageWrapperClass}>
        {stationLoading && <Loading />}
        <h1 className="page-heading mb-8">Our Timetable</h1>
        <div className="mb-8">
          <p>
            Want to search our programs?{" "}
            <Link to="/programs" className="font-bold">
              Click here
            </Link>
          </p>
        </div>
        <Tabs>
          <div className="grid grid-cols-12 gap-4">
            <div className="block col-span-12 lg:col-span-4  ">
              <TabList className={"border-0"}>
                {weekdays.map((day, i) => (
                  <Tab
                    className="cursor-pointer p-4 w-full flex jusitfy-between items-center rounded"
                    key={`${i}b`}
                  >
                    {day.readable}
                  </Tab>
                ))}
              </TabList>
            </div>
            <div className="col-span-12 lg:col-span-8">
              {weekdays.map((day, i) => (
                <TabPanel className="week-day" key={`w${i}`}>
                  {station &&
                    station.episodes &&
                    station.episodes.map((episode, j) =>
                      episode.timestamp >= day.start &&
                      episode.timestamp < day.end ? (
                        <SlotRow key={`${i}w${j}`} episode={episode} />
                      ) : (
                        ""
                      )
                    )}
                </TabPanel>
              ))}
            </div>
          </div>
        </Tabs>
        {/* {stationLoading !== true && (
          <div>
            {station &&
              station.episodes &&
              station.episodes.map((episode, i) => (
                <EpisodeRow key={i} episode={episode} />
              ))}
          </div>
        )} */}
      </div>
      <Banner />
    </Layout>
  );
}
