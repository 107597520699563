import { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import { API, STATION_SLUG } from "../utils/Constants";
import Feature from "../components/homepage/Feature";
import Slider from "react-slick";
import EpisodeSquare from "../components/EpisodeSquare";
import SectionSquare from "../components/SectionSquare";
import { RadioContext } from "../Context";
import { ThemeContext } from "../ThemeContext";
import PostCarousel from "../components/homepage/PostsCarousel";
import moment from "moment";
import TwinBanner from "../components/TwinBanner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PostFront from "../components/homepage/PostFront";
import Banner from "../components/Banner";

export default function Home() {
  const {
    station,
    stationLoading,
    homePageData,
    setHomePageData,
    homeBanners,
    onAir,
    episodes,
    sections,
    loading,
  } = useContext(RadioContext);
  const { theme } = useContext(ThemeContext);
  const now = moment().unix();

  const settingsEpisodes = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 8,
    slidesToScroll: 4,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout article={{ title: "Home" }}>
      {loading && (
        <div className="py-32">
          <Loading />
        </div>
      )}
      <div
        className={`home-wrapper w-full ${
          theme === "light" ? "lg:mt-20 mt-14" : "mt-0"
        }`}
      >
        {onAir && <Feature episode={onAir} />}

        {episodes && episodes.length > 0 && (
          <div className="episodes mb-3">
            <Link to={`/listen`}>
              <h2 className=" section-heading ">LATEST EPISODES</h2>
            </Link>
            <div className="-mx-2">
              <Slider {...settingsEpisodes}>
                {episodes.map((episode, i) => (
                  <EpisodeSquare episode={episode} key={i} />
                ))}
                <div className="episode px-2 ">
                  <Link
                    to={"/episodes"}
                    className="bg-gray-900 rounded-lg aspect-video  bg-opacity-30 flex items-center border-dashed border-2 border-gray-100 border-opacity-20 text-xl text-center bg-center bg-cover justify-center text-white"
                  >
                    VIEW ALL
                    <br />
                    EPISODE
                  </Link>
                </div>
              </Slider>
            </div>
          </div>
        )}
        {/* <TwinBanner className="mt-4 <mb-3>" type="home" index1={0} index2={1} /> */}
        {station && station.episodes && (
          <div className="episodes mb-3">
            <h2 className=" section-heading ">UPCOMING SHOWS</h2>
            <div className="-mx-2">
              <Slider {...settingsEpisodes}>
                {station.episodes.map((episode, i) =>
                  episode.timestamp > now ? (
                    <EpisodeSquare episode={episode} key={i} />
                  ) : (
                    ""
                  )
                )}
                {/* <div className="episode px-2 ">
                <Link
                  to={"/episodes"}
                  className="aspect-square  bg-opacity-30 flex items-center border-dashed border-2 border-gray-100 border-opacity-20 text-xl text-center bg-center bg-cover justify-center text-white"
                >
                  VIEW ALL
                  <br />
                  EPISODE
                </Link>
              </div> */}
              </Slider>
            </div>
          </div>
        )}
        {sections && sections.length > 0 && (
          <div className="episodes mb-3">
            <h2 className=" section-heading ">LATEST SEGMENTS</h2>
            <div className="-mx-2">
              <Slider {...settingsEpisodes}>
                {sections.map((section, i) => (
                  <SectionSquare section={section} key={i} />
                ))}
                <div className="episode px-2 ">
                  <Link
                    to={"/segments"}
                    className="bg-gray-900 rounded-lg aspect-video  bg-opacity-30 flex items-center border-dashed border-2 border-gray-100 border-opacity-20 text-xl text-center bg-center bg-cover justify-center text-white"
                  >
                    VIEW ALL
                  </Link>
                </div>
              </Slider>
            </div>
          </div>
        )}
        {/* {homeBanners && homeBanners.length > 0 && (
          <TwinBanner className="mt-4 mb-3" type="home" index1={1} index2={2} />
        )} */}

        {loading === false && stationLoading === true && (
          <div className="pt-12">
            <Loading />
          </div>
        )}
        {<PostCarousel postType={"podcast"} title={"LATEST PODCASTS"} />}
        {/* {homeBanners && homeBanners.length > 0 && (
          <TwinBanner className="mt-4 mb-3" type="home" index1={3} index2={4} />
        )} */}
        <div
          className="block xl:flex gap-4 align mb-12 mx-auto"
          style={{ maxWidth: "1500px", width: "100%" }}
        >
          <div className="w-full xl:w-2/3 ">
            {
              <PostFront
                postType={"posts"}
                filter={"categories=1"}
                title={"Latest News"}
                link={"/blogs/news"}
              />
            }
          </div>
          <div
            className="w-full xl:w-1/3 xl:sticky  sticky-font hidden xl:block"
            style={{ alignSelf: "flex-start", top: "70px" }}
          >
            <Banner type="home" index={1} className={"py-2"} />
          </div>
        </div>

        {homeBanners && homeBanners.length > 0 && (
          <TwinBanner
            className="mt-4 mb-3 lg:hidden"
            type="home"
            index1={0}
            index2={1}
          />
        )}
        <div
          className="block xl:flex gap-4 align mx-auto"
          style={{ maxWidth: "1500px", width: "100%" }}
        >
          <div
            className="w-full xl:w-1/3 xl:sticky  sticky-font  hidden xl:block"
            style={{ alignSelf: "flex-start", top: "70px" }}
          >
            {homeBanners && homeBanners.length > 0 && (
              <Banner type="home" index={0} className={"py-2"} />
            )}
          </div>
          <div className="w-full xl:w-2/3 mt-8 xl:mt-0">
            {
              <PostFront
                postType={"posts"}
                filter={"categories=400"}
                title={"SPONSORS"}
                link={"/blogs/sponsors-2"}
              />
            }
          </div>
        </div>
      </div>
    </Layout>
  );
}
