import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { PageWrapperClass, WpPages } from "../utils/Constants";
import Banner from "./Banner";
import PostPageContent from "../components/PostPageContent";
import "../styles/wp.css";
import Loading from "./Loading";

export default function Page({ slug }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function loadPosts() {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/pages?slug=${slug}&_embed`
      );

      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      const posts = await response.json();
      setPosts(posts);
      setLoading(false);
    }
    console.log("Got here", slug);
    loadPosts();
  }, []);

  return (
    <Layout
      route="posts"
      pathname="/posts"
      article={posts && posts.length > 0 && { title: posts[0].title.rendered }}
    >
      <div className={PageWrapperClass}>
        {loading === true && <Loading />}
        {posts.map((post, index) => (
          <PostPageContent post={post} key={index} />
        ))}
        {loading !== true && <Banner />}
      </div>
    </Layout>
  );
}
