import { useState, useEffect } from "react";
import PostSquare from "../PostSquare";
import Slider from "react-slick";
import LoadingRow from "../LoadingRow";
import PostRow from "../PostRow";
import PostFeatured from "../PostFeatured";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/solid";

export default function PostFront({
  postType,
  title,
  filter = "",
  link = null,
}) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const settingsEpisodes = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 8,
    slidesToScroll: 4,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    async function loadPosts() {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/${postType}?_embed&${filter}`
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      const posts = await response.json();
      setPosts(posts);
      setLoading(false);
    }
    if (posts.length === 0 && loading === false) loadPosts();
  }, []);
  return (
    <div className="mb-8 w-full">
      <h2 className="text-lg xl:text-4xl text-center font-bold mb-4">
        {title}
      </h2>
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-4">
        {loading === false && (
          <div className="block md:flex gap-2">
            {posts.length > 0 &&
              posts.map((post, index) =>
                index < 2 ? <PostFeatured post={post} key={index} /> : ""
              )}
          </div>
        )}
        {loading === false && (
          <div className="block md:flex gap-2">
            {posts.length > 0 &&
              posts.map((post, index) =>
                index > 1 && index <= 5 ? (
                  <PostFeatured
                    wClass="w-full md:w-1/4"
                    post={post}
                    key={index}
                  />
                ) : (
                  ""
                )
              )}
          </div>
        )}
        {/* {loading === false && (
          <div>
            {posts.length > 0 &&
              posts.map((post, index) =>
                index > 5 ? <PostRow post={post} key={index} /> : ""
              )}
          </div>
        )} */}
        {loading === true && <LoadingRow />}
      </div>
      {loading !== true && link && (
        <p className="text-center py-4">
          {" "}
          <Link className="text-center text-xl " to={link}>
            READ MORE
            <ArrowRightIcon className="ml-4 inline h-10 w-10" />
          </Link>
        </p>
      )}
    </div>
  );
}
