import Home from "./pages/Home";
import { useContext } from "react";
import { RadioContext } from "./Context";
import ReactPlayer from "react-player";
import * as React from "react";
import Section from "./pages/Segment";
import { Routes, Route, Redirect } from "react-router-dom";
import Programs from "./pages/Programs";
import Posts from "./pages/Posts";
import Series from "./pages/Series";
import Slug from "./pages/Slug";
import Podcast from "./pages/Podcast";
import Program from "./pages/Program";
import Episode from "./pages/Episode";
import PostsCategory from "./pages/PostsCategory";
import TimeTable from "./pages/TimeTable";
import Playlist from "./pages/Playlist";
import Header from "./components/header/Header";
import ScrollToTop from "./components/ScrollToTop";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Episodes from "./pages/Episodes";
import NotFoundPage from "./pages/404";

export default function App() {
  const {
    ref,
    updateDuration,
    updateProgress,
    setBuffering,
    playing,
    userId,
    track,
    pickNewTrack,
  } = useContext(RadioContext);
  return (
    <>
      <Header />
      <ReactPlayer
        ref={ref}
        progressInterval={50}
        onDuration={(progress) => updateDuration(progress)}
        // onPlay={(item) => console.log("here", item)}
        playing={playing}
        onProgress={(progress) => updateProgress(progress)}
        controls={false}
        onBuffer={() => setBuffering(true)}
        onBufferEnd={() => setBuffering(false)}
        onEnded={() => pickNewTrack()}
        height={0}
        width={0}
        url={`${
          track.url
            ? track.url.includes("?")
              ? `${track.url}&unique=${userId}&source=website`
              : `${track.url}?unique=${userId}&source=website`
            : null
        }`}
        config={{
          file: {
            forceAudio: true,
          },
        }}
      />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="programs" element={<Programs />} />
          <Route path="posts" element={<Posts />} />
          <Route path="program/:slug" element={<Program />} />
          <Route path="blogs/:slug/:pageNo" element={<PostsCategory />} />
          <Route path="blogs/:slug" element={<PostsCategory />} />
          <Route path="podcast/:slug" element={<Podcast />} />
          <Route path="episode/:timestamp" element={<Episode />} />
          <Route path="segment/:id" element={<Section />} />
          <Route path="playlist" element={<Playlist />} />
          <Route path="podcast-series/" element={<Series />} />
          <Route path="listen" element={<Episodes />} />
          <Route path="timetable" element={<TimeTable />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="/:slug" element={<Slug />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ScrollToTop>
    </>
  );
}
