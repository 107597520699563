export default function PostPageContent({ post = null }) {
  return (
    <div>
      {post && (
        <div className="post-tease">
          <h2 className="entry-title page-heading mb-8" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </div>
      )}
    </div>
  );
}
