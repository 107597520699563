import { Link } from "react-router-dom";
import { buildEpisodeTrack } from "../utils/Functions";
import AddToPlaylistButton from "./AddToPlaylistButton";
import PlayButton from "./PlayButton";
import moment from "moment";

export default function EpisodeSquare({ episode }) {
  const now = moment().unix();
  const thisTrack = buildEpisodeTrack({ episode });
  return (
    <div className="episode  ">
      <div className="px-1 w-full">
        <Link className="block w-full" to={`/episode/${episode.timestamp}`}>
          <div
            style={{
              backgroundImage: `url(${
                episode.webp
                  ? episode.webp.tile
                  : episode.image
                  ? episode.image.url
                  : episode.program &&
                    episode.program.image &&
                    episode.program.image.webp
                  ? episode.program.image.webp.tile
                  : episode.program && episode.program.image
                  ? episode.program.image.url
                  : ""
              })`,
            }}
            className=" shadow-xl rounded-lg aspect-video  bg-gray-200 bg-opacity-30 flex items-end text-center bg-center bg-cover justify-center text-white"
          >
            <div className=" shadow-xl rounded-lg px-3 pb-3 pt-8 flex gap-2 items-center justify-start w-full bg-gradient-to-t from-gray-900">
              {thisTrack && now > episode.timestamp + episode.duration && (
                <PlayButton
                  thisTrack={thisTrack}
                  iconClass={"h-8 w-8"}
                  divClass={"h-8 w-8"}
                />
              )}
              <p className="flex-1 xsmall-bold-heading text-left ">
                {episode.title ? episode.title : `${thisTrack.shortTitle}`}
              </p>
              {
                <AddToPlaylistButton
                  divClass="-ml-1"
                  className="w-6 h-6"
                  thisTrack={thisTrack}
                />
              }
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
