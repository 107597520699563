import PodcastSquare from "./homepage/PodcastSquare";
import BlogSquare from "./homepage/BlogSquare";

export default function PostSquare({ post = null }) {
  return post.type === "podcast" ? (
    <PodcastSquare post={post} />
  ) : post.type === "post" ? (
    <BlogSquare post={post} />
  ) : (
    <div>null</div>
  );
}
