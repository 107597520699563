import { useState, useEffect, useContext } from "react";
import { RadioContext } from "../Context";

export default function Banner({
  type = "site",
  index = 0,
  className = "mb-12",
}) {
  const { getSiteBanner, getHomeBanner } = useContext(RadioContext);
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    const goGetIt = async () => {
      let tmp;
      if (type === "site") {
        tmp = await getSiteBanner();
      } else {
        tmp = await getHomeBanner(index);
      }
      if (tmp) setBanner(tmp);
      console.log("Got here", tmp);
    };

    if (banner === null) {
      goGetIt();
    }
    // return () => {

    // };
  }, [banner]);

  return (
    <div
      className={`banner-ad w-full flex items-center justify-center gap-12 ${className}`}
    >
      {banner && (
        <a
          href={banner.acf.url && banner.acf.url}
          target="_new"
          alt={banner.title.rendered}
        >
          <img src={banner.acf.banner} className={"banner-img"} />
        </a>
      )}
    </div>
  );
}
