import AddToPlaylistButton from "./AddToPlaylistButton";
import PlayButton from "./PlayButton";
import { buildPodcastTrack } from "../utils/Functions";

export default function PodcastPageContent({ post = null }) {
  const thisTrack = buildPodcastTrack({ podcast: post });
  return (
    <div>
      {post && (
        <div className="post-tease">
          <h2
            className="entry-title page-heading"
            dangerouslySetInnerHTML={{ __html: post.title.rendered }}
          />
          <div className="flex gap-2 items-center mb-4 mt-4">
            {thisTrack && <PlayButton thisTrack={thisTrack} forceDisplay={true} />}
            {thisTrack && (
              <AddToPlaylistButton forceDisplay={true}
                buttonText={{
                  in: "Added to playlist",
                  out: "Add to playlist",
                }}
                thisTrack={thisTrack}
              />
            )}
          </div>
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          />
        </div>
      )}
    </div>
  );
}
