import { Link } from "react-router-dom";
export default function SeriesRow({ post = null }) {
  return (
    <div>
      {post && (
        <div className="post-tease flex gap-4">
          <Link to={`/podcast/${post.slug}`}>
            <div
              style={{
                backgroundImage: `url(${post.image && post.image})`,
              }}
              className="w-24 h-24 aspect-square bg-gray-200 bg-opacity-30 flex items-end text-center bg-center bg-cover justify-center text-white"
            ></div>
          </Link>
          <div>
            <Link to={`/series/${post.slug}`}>
              <h2
                className="entry-title list-heading mb-4"
                dangerouslySetInnerHTML={{ __html: post.name }}
              />
            </Link>
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{ __html: post.description }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
