import { Link } from "react-router-dom";
import { buildPodcastTrack } from "../../utils/Functions";
import PlayButton from "../PlayButton";
import AddToPlaylistButton from "../AddToPlaylistButton";

export default function PodcastSquare({ post = null }) {
  const thisTrack = buildPodcastTrack({ podcast: post });
  return (
    <div>
      {post && (
        <div className="episode">
          <div className="px-2 w-full ">
            <Link
              className=" rounded-xl shadow-xl block w-full"
              to={`/podcast/${post.slug}`}
            >
              <div
                style={{
                  backgroundImage: `url(${
                    post.type === "podcast" ? post.episode_featured_image : null
                  })`,
                }}
                className=" rounded-xl shadow-xl aspect-video bg-gray-200 bg-opacity-30 flex items-end text-center bg-center bg-cover justify-center text-white"
              >
                <div className="shadow-xl rounded-lg px-3 pb-3 pt-8 flex gap-2 items-center justify-start w-full bg-gradient-to-t from-gray-900">
                  {thisTrack && (
                    <PlayButton
                      iconClass="w-8 h-8"
                      divClass="w-8 h-8"
                      forceDisplay={true}
                      thisTrack={thisTrack}
                    />
                  )}
                  <p className="flex-1 xsmall-bold-heading text-left ">
                    {post.title ? post.title.rendered : `${thisTrack.title}`}{" "}
                  </p>
                  {
                    <AddToPlaylistButton
                      forceDisplay={true}
                      divClass="-ml-1"
                      className="w-6 h-6"
                      thisTrack={thisTrack}
                    />
                  }
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
