import { Link } from "react-router-dom";
import { buildEpisodeTrack } from "../utils/Functions";
import { ArrowRightIcon } from "@heroicons/react/solid";
import AddToPlaylistButton from "./AddToPlaylistButton";
import { RadioContext } from "../Context";
import PlayButton from "./PlayButton";
import moment from "moment";
import { useContext } from "react";

export default function SlotRow({ episode }) {
  const { liveStream } = useContext(RadioContext);
  const thisTrack = buildEpisodeTrack({ episode });
  const now = moment().unix();
  const onAir =
    now >= episode.timestamp && now < episode.timestamp + episode.duration
      ? true
      : false;
  return (
    <div className="episode mb-8">
      <div className="w-full">
        <Link
          className="w-full  gap-4 flex"
          to={`/program/${episode.program.slug}`}
        >
          <div>
            <div
              style={{
                backgroundImage: `url(${
                  episode.program.image && episode.program.image.webp
                    ? episode.program.image.webp.tile
                    : ""
                })`,
              }}
              className="program-image aspect-square max-w-1/5 bg-gray-200 bg-opacity-30 flex items-center text-center bg-center bg-cover justify-center text-white"
            >
              {onAir ? (
                <PlayButton
                  thisTrack={{
                    ...thisTrack,
                    title: `ON AIR: ${episode.program.name}`,
                    image: episode?.image?.url,
                    link: `/episode/${episode.timestamp}`,
                    url: liveStream.url,
                    isLive: true,
                  }}
                />
              ) : (
                <PlayButton thisTrack={thisTrack} />
              )}
            </div>
          </div>
          <div className="flex-1 flex gap-2 items-end">
            <div className="flex-1">
              <h3 className="section-heading mb-2">
                {onAir === true && (
                  <span className="text-blue-500">ON AIR NOW: </span>
                )}
                {episode && episode.program.name}
              </h3>
              {episode.program.grid_one_liner && (
                <p className="mb-2 text-sm italic">
                  {episode.program.grid_one_liner}
                </p>
              )}
              {episode.program.introduction && (
                <p className="mb-2 text-sm">{episode.program.introduction}</p>
              )}

              <p className="text-sm">
                Time: {moment.unix(episode.timestamp).format("h:mm A")}
              </p>
              <p className="text-sm">Duration: {thisTrack.time}</p>
              <p className="xsmall-heading mb-2">
                View program page <ArrowRightIcon className="inline h-4 w-4" />
              </p>
            </div>
            <div>
              <AddToPlaylistButton thisTrack={thisTrack} />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
