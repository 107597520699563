import { Link } from "react-router-dom";
import { buildEpisodeTrack } from "../utils/Functions";
import { RadioContext } from "../Context";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { useContext } from "react";
import AddToPlaylistButton from "./AddToPlaylistButton";
import PlayButton from "./PlayButton";

export default function EpisodeRow({ episode }) {
  const { track, playing, playAudio, setTrack, buffering } =
    useContext(RadioContext);
  const thisTrack = buildEpisodeTrack({ episode });
  return (
    <div className="episode mb-8">
      <div className="w-full">
        <Link
          className="w-full  gap-4 flex"
          to={`/episode/${episode.timestamp}`}
        >
          <div>
            <div
              style={{
                backgroundImage: `url(${
                  episode.image && episode.image.webp
                    ? episode.image.webp.tile
                    : episode.program &&
                      episode.program.image &&
                      episode.program.image.webp
                    ? episode.program.image.webp.tile
                    : episode.program.image && episode.program.image.url
                    ? episode.program.image.url
                    : ""
                })`,
              }}
              className="program-image aspect-square max-w-1/5 bg-gray-200 bg-opacity-30 flex items-center text-center bg-center bg-cover justify-center text-white"
            >
              <PlayButton thisTrack={thisTrack} />
            </div>
          </div>
          <div className="flex-1 flex gap-2 items-end">
            <div className="flex-1">
              <h3 className="section-heading mb-2">
                {thisTrack && thisTrack.title}
              </h3>
              <p className="text-sm">Duration: {thisTrack.time}</p>
              <p className="text-sm mb-2">Aired: {episode.readable}</p>
              <p className="xsmall-heading">
                View details <ArrowRightIcon className="inline h-4 w-4" />
              </p>
            </div>
            <div>
              <AddToPlaylistButton thisTrack={thisTrack} />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
