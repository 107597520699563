import { Link } from "react-router-dom";

export default function ProgramRow({ program }) {
  return (
    <div className="program mb-8">
      <div className="px-2 w-full">
        <Link className="w-full  gap-4 flex" to={`/program/${program.slug}`}>
          <div>
            <div
              style={{
                backgroundImage: `url(${
                  program.image && program.image.webp
                    ? program.image.webp.tile
                    : ""
                })`,
              }}
              className="program-image aspect-square max-w-1/5 bg-gray-200 bg-opacity-30 flex items-end text-center bg-center bg-cover justify-center text-white"
            ></div>
          </div>
          <div className="flex-1">
            <h3 className="section-heading">{program && program.name}</h3>
            {program && program.grid_one_liner && (
              <p className="text-sm italic">{program.grid_one_liner}</p>
            )}
            {program && program.introduction && (
              <p className="text-sm italic">{program.introduction}</p>
            )}
            {program && program.genre_string && (
              <p className="text-sm">Genre: {program.genre_string}</p>
            )}
            {program && program.presenter_string && (
              <p className="text-sm">
                Presented By: {program.presenter_string}
              </p>
            )}
            {program && program.producer && (
              <p className="text-sm">Produced By: {program.producer}</p>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
}
