import Banner from "./Banner";

export default function TwinBanner({
  type = "home",
  index1 = 0,
  index2 = 0,
  className = "",
}) {
  return (
    <div
      className={`flex flex-col items-center justify-center md:flex-row gap-4 twin-banner ${className}`}
    >
      <div className=" ">
        <Banner type="home" index={index1} className={"py-2"} />
      </div>
      <div className=" ">
        <Banner type="home" index={index2} className={"py-2"} />
      </div>
    </div>
  );
}
