export const STATION_ID = `${process.env.REACT_APP_STATION_ID}`;
export const STATION_SLUG = `${process.env.REACT_APP_SLUG}`;
export const API = `${process.env.REACT_APP_API}`;
export const STREAM_URL = `${process.env.REACT_APP_STREAM_URL}`;
export const HLS = `${process.env.REACT_APP_HLS}`;
export const SITE_TITLE = `${process.env.REACT_APP_SITE_TITLE}`;
export const ONESECOND =
  "https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3";
export const PageWrapperClass =
  "mx-auto px-4 pt-20 lg:pt-32 pb-20 max-w-full w-full xl:w-9/12";
export const HomeBannerId = 8539;
export const SiteBannerId = 8540;

export const WpPages = [
  "aboutus",
  "greatideaforashow",
  "greatideaforapodcast",
  "donations",
  "bequests",
  "terms-of-use",
  "privacypolicy",
  "volunteer",
  "sponsorship",
  "how-to-listen",
];
