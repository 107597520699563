import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { PageWrapperClass } from "../utils/Constants";
import SeriesRow from "../components/SeriesRow";
import "../styles/wp.css";
import Banner from "../components/Banner";

export default function Series() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    async function loadPosts() {
      const response = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/series?_meta&_embed`
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const posts = await response.json();
      setPosts(posts);
    }

    loadPosts();
  }, []);
  return (
    <Layout route="posts" pathname="/posts" article={{ title: "Our Podcasts" }}>
      <div className={PageWrapperClass}>
        <h1 className="page-heading mb-8">Podcast Series</h1>
        {posts.map((post, index) => (
          <SeriesRow post={post} key={index} />
        ))}
      </div>
    </Layout>
  );
}
