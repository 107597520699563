import { Link } from "react-router-dom";

export default function BlogSquare({ post = null }) {
  return (
    <div>
      {post && (
        <div className="episode">
          <div className="px-2 w-full">
            <Link className="block w-full" to={`/${post.slug}`}>
              <div
                style={{
                  backgroundImage: `url(${
                    post.type === "post"
                      ? post._embedded["wp:featuredmedia"][0]?.source_url
                      : null
                  })`,
                }}
                className="rounded-xl shadow-xl  aspect-square bg-gray-200 bg-opacity-30 flex items-end text-center bg-center bg-cover justify-center text-white"
              >
                <div className="px-2 rounded-xl  pt-8 flex gap-2 items-center justify-start w-full bg-gradient-to-t from-gray-900">
                  <p className="pb-3 flex-1 xsmall-bold-heading text-left">
                    {post.title && post.title.rendered}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
