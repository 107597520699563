import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { useParams, useNavigate } from "react-router-dom";
import { PageWrapperClass } from "../utils/Constants";
import PostRow from "../components/PostRow";
import Loading from "../components/Loading";
import "../styles/wp.css";
import Banner from "../components/Banner";

export default function PostsCategory({ location }) {
  const { slug, pageNo } = useParams();
  let navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [page, setpage] = useState(pageNo ? pageNo : 1);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function loadPosts() {
      setLoading(true);
      const cat = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/categories?slug=${slug}&_embed`
      );
      if (cat) {
        const cat_ = await cat.json();
        if (cat_.length > 0) {
          await setCategory(cat_[0]);
          // console.log("here", cat_);
          const response = await fetch(
            `${process.env.REACT_APP_WP}/wp-json/wp/v2/posts?categories=${cat_[0].id}&_embed&page=${page}`
          );
          setLoading(false);
          const posts = await response.json();
          setPosts(posts);
        }
        if (cat_.length === 0) {
          // oups! something went wrong
          return navigate("/404");
        }
      }

      setLoading(false);
    }
    loadPosts();
  }, []);

  useEffect(() => {
    async function loadPosts() {
      setLoading(true);
      const cat = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/categories?slug=${slug}&_embed`
      );
      if (cat) {
        const cat_ = await cat.json();
        if (cat_.length > 0) {
          await setCategory(cat_[0]);
          // console.log("here", cat_);
          const response = await fetch(
            `${process.env.REACT_APP_WP}/wp-json/wp/v2/posts?categories=${cat_[0].id}&_embed&page=${page}`
          );
          setLoading(false);
          const posts = await response.json();
          setPosts(posts);
        }
        if (cat_.length === 0) {
          // oups! something went wrong
          return navigate("/404");
        }
      }

      setLoading(false);
    }
    loadPosts();
  }, [page]);
  return (
    <Layout
      route="posts"
      pathname="/posts"
      article={{
        title: `Blog Category - ${category && category.name}`,
        description: category && category.description,
      }}
    >
      <div className={PageWrapperClass}>
        {category && <h1 className="page-heading mb-12">{category.name}</h1>}
        {loading === true && <Loading />}
        {posts.map((post, index) => (
          <PostRow post={post} key={index} />
        ))}
        {loading !== true && posts && posts.length > 0 && <Banner />}
      </div>
    </Layout>
  );
}
