import { Helmet } from "react-helmet";
export default function Seo({
  seo,
  //   article,
  siteLanguage = null,
  pathname = "",
}) {
  return (
    <>
      <Helmet>
        <title>
          {seo && seo.title
            ? `${seo.title} - ${process.env.REACT_APP_SITE_TITLE}`
            : `${process.env.REACT_APP_SITE_TITLE}`}
        </title>
        <html lang={siteLanguage ? siteLanguage : "en"} />
        <link rel="canonical" href={pathname} />
        <meta
          name="description"
          content={seo && seo.description && seo.description}
        />

        {/* {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )} */}
        {/* <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script> */}
      </Helmet>
      {/* <Facebook
        desc={seo.description}
        image={image}
        title={seo.title}
        type={article ? "article" : "website"}
        url={pathname}
        locale={siteLocale ? siteLocale : "en_gb"}
      />
      <Twitter
        title={seo.title}
        image={image}
        desc={seo.description}
        username={twitterUsername}
      /> */}
    </>
  );
}
