import React from "react";

const ProgressBar = ({ bgcolor = "blue", completed, index, seek  }) => {
  //   const { bgcolor, completed } = props;

  const containerStyles = {
    height: 5,
    width: "100%", 
    borderRadius: 0,
    // margin: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    // backgroundColor: bgcolor,
    transition: "width 0.2s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
  };

  // const labelStyles = {
  //   padding: 5,
  //   color: "white",
  //   fontWeight: "bold",
  // }; 

  return (
    <div style={containerStyles} className={'cursor-pointer bg-gray-400 bg-opacity-60'} id={`player-${index}`}>
      <div className="bg-gray-400 " style={fillerStyles}>
        {/* <span style={labelStyles}>{`${completed}%`}</span> */}
      </div>
    </div>
  );
};

export default ProgressBar;
