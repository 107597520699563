import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import Layout from "../components/Layout";
import { PageWrapperClass, WpPages } from "../utils/Constants";
import Banner from "./Banner";
import PostPageContent from "../components/PostPageContent";
import "../styles/wp.css";

export default function Post({ slug }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function loadPosts() {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/posts?slug=${slug}&_embed`
      );

      if (!response.ok) {
        // oups! something went wrong
        setLoading(false);
        return;
      }
      setLoading(false);

      const posts = await response.json();
      setPosts(posts);
    }
    console.log("Got here");
    loadPosts();
  }, []);

  return (
    <Layout
      route="posts"
      pathname="/posts"
      article={posts && posts.length > 0 && { title: posts[0].title.rendered }}
    >
      <div className={PageWrapperClass}>
        {loading === true && <Loading />}
        {posts.map((post, index) => (
          <PostPageContent post={post} key={index} />
        ))}
        {loading !== true && posts && posts.length > 0 && <Banner />}
      </div>
    </Layout>
  );
}
