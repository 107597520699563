import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { PageWrapperClass } from "../utils/Constants";
import Loading from "../components/Loading";
import PodcastPageContent from "../components/PodcastPageContent";
import "../styles/wp.css";
import { useParams } from "react-router-dom";
import Banner from "../components/Banner";

export default function Podcast() {
  const { slug } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function loadPosts() {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/podcast?slug=${slug}&_embed`
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      const posts = await response.json();
      setPosts(posts);
      setLoading(false);
    }
    loadPosts();
  }, []);

  return (
    <Layout route="posts" pathname="/posts">
      <div className={PageWrapperClass}>
        {loading && <Loading />}
        {posts.map((post, index) => (
          <PodcastPageContent post={post} key={index} />
        ))}
        <Banner />
      </div>
    </Layout>
  );
}
