import { CheckIcon, PlusIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { RadioContext } from "../Context";
import ReactTooltip from "react-tooltip";
import moment from "moment";

export default function AddToPlaylistButton({
  className = "h-12 w-12 ",
  thisTrack = null,
  divClass = "",
  buttonClass = "",
  buttonText = null,
  forceDisplay = false,
}) {
  const { playlist, setPlaylist, removeFromPlaylist, setToastMessage } =
    useContext(RadioContext);
  const [inPlayList, setInPlaylist] = useState(false);
  const now = moment().unix();
  const tooOld = moment().add(-8, "weeks").unix();
  useEffect(() => {
    const test = playlist.filter((item) => item.url === thisTrack.url);
    if (test.length > 0) {
      setInPlaylist(true);
    } else {
      setInPlaylist(false);
    }
  }, [playlist]);
  return (thisTrack.timestamp > tooOld && thisTrack.timestamp < now) ||
    forceDisplay === true ? (
    <div className={divClass}>
      <button
        data-tip={`${
          inPlayList === true ? "Remove from playlist" : "Add to playlist"
        }`}
        className={`${buttonText && "flex  items-center gap-2"} ${buttonClass}`}
        onClick={(e) => {
          e.preventDefault();
          if (inPlayList === true) {
            removeFromPlaylist(thisTrack);
            setToastMessage(`${thisTrack.title} removed from playlist`);
          } else {
            setPlaylist([...playlist, thisTrack]);
            setToastMessage(`${thisTrack.title} added to your playlist`);
          }
        }}
      >
        {inPlayList === true ? (
          <CheckIcon className={className} />
        ) : (
          <PlusIcon className={className} />
        )}
        {buttonText && (
          <span>{inPlayList === true ? buttonText.in : buttonText.out}</span>
        )}
      </button>
      <ReactTooltip />
    </div>
  ) : (
    ""
  );
}
