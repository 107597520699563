import { HLS } from "./Constants";
import moment from "moment";

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const buildEpisodeTrack = ({ episode }) => {
  return {
    isLive: false,
    readable: episode.readable,
    shortTitle: episode.program.name,
    title: `${
      episode.title
        ? episode.title
        : `${episode.program.name} - ${episode.readable}`
    }`,
    url: `${HLS}/96/${episode.timestamp}"/${episode.duration}/listen.m3u8`,
    link: `/episode/${episode.timestamp}`,
    time: `${
      episode.duration >= 60 * 60
        ? `${moment.utc(episode.duration * 1000).format("H")}hr ${moment
            .utc(episode.duration * 1000)
            .format("m")}min`
        : `${moment.utc(episode.duration * 1000).format("m")}min`
    }`,
    type: "track",
    timestamp: episode.timestamp,
    image: episode.image
      ? episode.image.url
      : episode.program && episode.program.image
      ? episode.program.image.url
      : null,
  };
};

export const buildPodcastTrack = ({ podcast }) => {
  return {
    isLive: false,
    readable: podcast.title.rendered,
    title: `${podcast.title.rendered}`,
    url: `${podcast.download_link}`,
    link: `/podcast/${podcast.slug}`,
    time: null,
    type: "podcast",
    timestamp: null,
    image: podcast.episode_featured_image,
  };
};

export const buildSectionTrack = ({ segment }) => {
  return {
    isLive: false,
    readable: segment.readable,
    timestamp: segment.timestamp,
    title: `${segment.title}`,
    url: `${HLS}/96/${segment.timestamp}"/${segment.duration}/listen.m3u8`,
    link: `/segment/${segment.id}`,
    time: `${
      segment.duration >= 60 * 60
        ? `${moment.utc(segment.duration * 1000).format("H")}hr ${moment
            .utc(segment.duration * 1000)
            .format("m")}min`
        : `${moment.utc(segment.duration * 1000).format("m")}min`
    }`,
    type: "segment",
    image: segment.image
      ? segment.image.url
      : segment.episode && segment.episode.image
      ? segment.episode.image.url
      : null,
  };
};
