import React from "react";

import Layout from "../components/Layout";

export default function NotFoundPage() {
  return (
    <Layout>
      <div className="mt-20">Not Found</div>
    </Layout>
  );
}
