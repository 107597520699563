import { useContext, useState, useEffect } from "react";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { RadioContext } from "../Context";
import ProgramFilter from "../ProgramFilter";
import ProgramRow from "../components/ProgramRow";
import { Link } from "react-router-dom";
import { PageWrapperClass } from "../utils/Constants";

export default function Programs() {
  const { station, stationLoading } = useContext(RadioContext);
  const [programs, setPrograms] = useState();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (station) {
      setPrograms(station.programs);
      // console.log("p", station.programs);
    }
  }, [station]);

  useEffect(() => {
    if (station) {
      let newStations = station.programs;
      if (filter !== "") {
        newStations = station.programs.filter((item) => {
          return (
            item.name.toLowerCase().includes(filter.toLowerCase()) ||
            item.presenter_string
              ?.toLowerCase()
              .includes(filter.toLowerCase()) ||
            item.genre_string?.toLowerCase().includes(filter.toLowerCase()) ||
            item.producer_string
              ?.toLowerCase()
              .includes(filter.toLowerCase()) ||
            item.language?.toLowerCase().includes(filter.toLowerCase())
          );
        });
      }
      setPrograms(newStations);
      console.log("p", station.programs);
    }
  }, [filter]);
  return (
    <Layout
      pathname={`/programs`}
      seo={{ title: "Our Programs" }}
      route={"programs"}
    >
      <div className={PageWrapperClass}>
        {stationLoading && <Loading />}
        <h1 className="page-heading mb-8">Our Programs</h1>
        <div className="mb-8">
          <p>
            Looking for our timetable?{" "}
            <Link to="/timetable" className="font-bold">
              Click here
            </Link>
          </p>
        </div>
        {stationLoading !== true && (
          <div>
            <div className="mb-8">
              <ProgramFilter filter={filter} setFilter={setFilter} />
            </div>
            {filter && filter !== "" && (
              <div className="mb-8 flex gap-4">
                <div>Searching for:</div>
                <div>{filter}</div>
              </div>
            )}
            {programs &&
              programs.map((program, i) => (
                <ProgramRow program={program} key={`${i}-p`} />
              ))}
          </div>
        )}
      </div>
    </Layout>
  );
}
