import { useState, useEffect } from "react";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { API, STATION_SLUG, PageWrapperClass } from "../utils/Constants";
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EpisodeRow from "../components/EpisodeRow";
import SectionRow from "../components/SectionRow";
import "react-tabs/style/react-tabs.css";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import Banner from "../components/Banner";

// const timeFormatLog = "h:mm:ss a";

export default function Program() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [program, setProgram] = useState(null);
  const [thisTrack, setThisTrack] = useState(null);
  const [links, setLinks] = useState(null);

  useEffect(() => {
    const getFront = async () => {
      if (loading) return;
      setLoading(true);
      var req = await fetch(
        `${API}/public/station/${STATION_SLUG}/program/${slug}?showEpisodes=true&showSections=true`
      );
      const results = await req.json();
      if (results.data) {
        setProgram(results.data);
        if (results.data.links) {
          const json = JSON.parse(results.data.links);
          setLinks(json);
        }
      }
      setLoading(false);
    };
    if (!program && loading === false) getFront();
  });

  return (
    <Layout
      article={
        program
          ? { title: `${program.name} Program Page` }
          : { title: "Loading Program" }
      }
    >
      <div className={PageWrapperClass}>
        {loading && <Loading />}
        {program && <h6 className="xsmall-heading font-bold mb-2">PROGRAM:</h6>}
        {program && program.name && (
          <h1 className="page-heading mb-8">{program.name}</h1>
        )}
        {program && (
          <div
            className="w-full gap-4 md:flex mb-8"
            to={`/program/${program.slug}`}
          >
            {program.image && (
              <div className="w-full md:w-1/5 md:max-w-1/5 mb-4">
                <img
                  src={program.image.url}
                  alt={program.name}
                  className={"w-full h-auto"}
                />
              </div>
            )}
            <div className="md:flex-1 mb-4">
              {program && program.grid_one_liner && (
                <p className="text-lg">{program.grid_one_liner}</p>
              )}
              {program && program.introduction && (
                <p className="text-base">{program.introduction}</p>
              )}
              {program && program.presenter_string && (
                <p className="text-base">{program.presenter_string}</p>
              )}
            </div>
          </div>
        )}
        {program && (
          <Tabs>
            <TabList>
              {program && program.episodes && program.episodes.length > 0 && (
                <Tab>Episodes</Tab>
              )}
              {program && program.sections && program.sections.length > 0 && (
                <Tab>Segments</Tab>
              )}
              {program && <Tab>About</Tab>}
              {program && program.links && links && links.length > 0 && (
                <Tab>Program Links</Tab>
              )}
            </TabList>

            {program && program.episodes && program.episodes.length > 0 && (
              <TabPanel>
                <div className="py-4">
                  {program &&
                    program.episodes &&
                    program.episodes.map((episode, i) => (
                      <EpisodeRow
                        episode={{ ...episode, program: program }}
                        program={program}
                        key={`${i}e`}
                      />
                    ))}
                </div>
              </TabPanel>
            )}
            {program && program.sections && program.sections.length > 0 && (
              <TabPanel>
                <div className="py-4">
                  {program &&
                    program.sections &&
                    program.sections.map((section, i) => (
                      <SectionRow
                        section={{ ...section, program: program }}
                        key={`${i}s`}
                      />
                    ))}
                </div>
              </TabPanel>
            )}
            <TabPanel>
              <div className="py-4">
                {program && program.bio && (
                  <p
                    itemProp="description"
                    className="mb-8 mt-3 mb-3  text-sm"
                    dangerouslySetInnerHTML={{
                      __html: program.bio
                        ? program.bio.split("\n").join("<br>")
                        : "",
                    }}
                  ></p>
                )}
              </div>
            </TabPanel>
            {program && program.links && links && links.length > 0 && (
              <TabPanel>
                <div className="py-4">
                  {links &&
                    links.map((link, i) => (
                      <div key={`l${i}`} className={"w-full mb-8"}>
                        <p>
                          <a
                            href={link.url}
                            target="_blank"
                            className={"hover:underline"}
                          >
                            {link.name}{" "}
                            <ArrowCircleRightIcon className="inline w-4 h-4 ml-4  " />
                          </a>
                        </p>
                        {link.description ? (
                          <p
                            itemProp="description"
                            className="mb-8 mt-3  text-sm"
                            dangerouslySetInnerHTML={{
                              __html: link.description,
                            }}
                          ></p>
                        ) : null}
                      </div>
                    ))}
                </div>
              </TabPanel>
            )}
          </Tabs>
        )}
        <Banner className="py-12" />
      </div>
    </Layout>
  );
}
