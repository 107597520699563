import { CheckIcon } from "@heroicons/react/solid";
import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { RadioContext } from "../Context";
import PlayListItem from "../components/PlayListItem";
import { PageWrapperClass } from "../utils/Constants";
import Banner from "../components/Banner";

export default function Playlist() {
  const { playlist, setPlaylist, track, playing, setPlaying, setTrack } =
    useContext(RadioContext);

  return (
    <Layout
      pathname={`/episodes`}
      route={"episodes"}
      article={{
        title: "My Playlist",
        description:
          "Fill your playlist with episodes and segments around our site and listen back anytime you like.",
      }}
    >
      <div className={PageWrapperClass}>
        <Banner />
        <h1 className="page-heading mb-8">My Playlist</h1>
        {playlist.length > 0 ? (
          <div>
            {playlist.map((current, i) => (
              <PlayListItem thisTrack={current} key={i} />
            ))}
          </div>
        ) : (
          <>
            <p className="mb-4">You dont have anything in your playlist yet.</p>
            <p>
              Checkout out our{" "}
              <Link to={"/"} className={"underline"}>
                latest segments and episodes
              </Link>{" "}
              and use the <CheckIcon className="w-8 h-8 inline" /> to start
              adding to your list.
            </p>
          </>
        )}
      </div>
      <Banner />
    </Layout>
  );
}
