import React, { useEffect, useState, createContext } from "react";
import { API, STATION_SLUG } from "./utils/Constants";
import moment from "moment";
import { uuidv4 } from "./utils/Functions";
import { SiteBannerId, HomeBannerId } from "./utils/Constants";

const DEFAULT_TRACK = {
  program: null,
  original_id: "live-stream",
  title: "Live Stream",
  url: `https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3`,
  start: null,
  isLive: true,
};

const getUid = () => {
  const item = window.localStorage.getItem("mrid");
  if (item) {
    return item;
  } else {
    const uuid = uuidv4();
    window.localStorage.setItem("mrid", uuid);
    return uuid;
  }
};

const defaultContext = {
  currentTrack: DEFAULT_TRACK,
  sidebar: false,
  mobileSidebar: false,
};
export const RadioContext = createContext(defaultContext);

export const RadioProvider = ({ children }) => {
  const defaultStream = process.env.REACT_APP_STREAM_URL
    ? process.env.REACT_APP_STREAM_URL
    : null;
  const userId = getUid();
  const [streamProgress, setStreamProgress] = useState(0);
  const [streamPercent, setStreamPercent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [buffering, setBuffering] = useState(false);
  const [length, setLength] = useState(0);
  const [player, setPlayer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [track, setTrack] = useState(DEFAULT_TRACK);
  const [playlist, setPlaylist] = useState([]);
  // const [userId, setUserId] = useState(null);
  const [station, setStation] = useState(null);
  const [stationLoading, setStationLoading] = useState(null);
  const [liveStream, setLiveStream] = useState(null);
  const [initPlaylist, setInitPlaylist] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [homePageData, setHomePageData] = useState(null);
  const [homeBanners, setHomeBanners] = useState([]);
  const [siteBanners, setSiteBanners] = useState([]);
  const [onAir, setOnAir] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [sections, setSections] = useState(null);
  const [loading, setLoading] = useState(true); // homepage data

  useEffect(() => {
    const getFront = async () => {
      setLoading(true);
      // var req = await fetch(`${API}/public/station/${STATION_SLUG}/homepage`);
      var req = await fetch(
        `https://radio-online.nyc3.cdn.digitaloceanspaces.com/cached/stations/eastside-89-7fm/4-homepage.json`
      );
      const results = await req.json();
      console.log(results);
      setHomePageData(results.data);
      if (results.data.episodes) {
        if (results.data.episodes.length > 0) {
          setOnAir(results.data.episodes[0]);
        }
        const tmp = results.data.episodes;
        tmp.shift();
        setEpisodes(tmp);
        setSections(results.data.sections);
      }
      setLoading(false);
    };
    if (episodes.length === 0) {
      getFront();
    }
  }, []);

  const calculateOnelineNow = async () => {
    if (station && station.episodes && station.episodes.length > 0) {
      let test = moment().unix();
      const oa = station.episodes.filter(
        (item) => item.timestamp <= test && item.timestampEnd >= test
      );
      if (oa && oa.length > 0) {
        setOnAir(oa[0]);
        setLiveStream({
          ...liveStream,
          isLive: true,
          url: defaultStream ? defaultStream : station.icecast_stream,
          link: `program/${oa[0]?.program?.slug}`,
          time: null,
          title: `LIVE STREAM: ${oa[0]?.program?.name}`,
        });
      }
    }
  };

  useEffect(() => {
    const calculateOnelineNow = async () => {
      console.log("Calculating on air now");
      if (station && station.episodes && station.episodes.length > 0) {
        let test = moment().unix();
        const oa = station.episodes.filter(
          (item) => item.timestamp <= test && item.timestampEnd >= test
        );
        if (oa && oa.length > 0) {
          setOnAir(oa[0]);
          setLiveStream({
            ...liveStream,
            isLive: true,
            url: defaultStream ? defaultStream : station.icecast_stream,
            link: `program/${oa[0]?.program?.slug}`,
            time: null,
            title: `LIVE STREAM: ${oa[0]?.program?.name}`,
          });
        }
      }
    };

    calculateOnelineNow();
    setInterval(() => calculateOnelineNow(), 30000);
  }, [station]);

  const getSiteBanner = async () => {
    let results = [...siteBanners];
    if (siteBanners.length === 0) {
      const req = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/banner-ads?banner_cat=${SiteBannerId}&_embed&acf_format=standard`
      );
      results = await req.json();
      await setSiteBanners(results);
    }
    let thisOne = results[Math.floor(Math.random() * siteBanners.length)];
    if (thisOne) return thisOne;

    return false;
  };

  const getHomeBanner = async (index = 0) => {
    let results = [...homeBanners];
    if (homeBanners.length === 0) {
      const req = await fetch(
        `${process.env.REACT_APP_WP}/wp-json/wp/v2/banner-ads?banner_cat=${HomeBannerId}&_embed&acf_format=standard`
      );
      results = await req.json();
      await setHomeBanners(results);
    }
    if (results[index]) return results[index];
    if (results[0]) return results[0];

    return false;
  };

  // here we pull all our station details
  useEffect(() => {
    const getStation = async () => {
      if (stationLoading === true) return;
      setStationLoading(true);
      // var req = await fetch(
      //   `${API}/public/station/${STATION_SLUG}?showFuture=true`
      // );
      var req = await fetch(
        `https://radio-online.nyc3.digitaloceanspaces.com/cached/stations/eastside-89-7fm/eastside-89-7fm.json`
      );
      const results = await req.json();
      if (results.data) {
        await setStation(results.data);
        // setLiveStream({
        //   isLive: true,
        //   title: onAir ? `LIVE STREAM: ${onAir?.program?.name}` : `Live Stream`,
        //   url: defaultStream ? defaultStream : results.data.icecast_stream,
        //   link: null,
        //   time: null,
        // });
        setStationLoading(false);
        // await calculateOnelineNow();
      }
    };
    if (!station) getStation();
  }, []);

  let ref = (player) => {
    setPlayer(player);
  };

  const playAudio = (e) => {
    setPlaying(!playing);
  };

  const newSeek = (percent) => {
    player.seekTo(percent / 100, "fraction");
  };

  const updateProgress = (progress) => {
    var per = (progress.playedSeconds / length) * 100;
    setStreamPercent(Math.floor(per));
    setStreamProgress(Math.floor(Math.floor(progress.playedSeconds)));
  };

  const updateTime = (event) => {
    var bcr = event.target.getBoundingClientRect();
    // let seekValue = event.clientX - event.target.offsetLeft;
    let percent = (event.clientX - bcr.left) / bcr.width;
    player.seekTo(percent);
  };

  const updateDuration = (newDuration) => {
    setLength(Math.floor(newDuration));
    setDuration(
      moment("2015-01-01")
        .startOf("day")
        .seconds(Math.floor(newDuration))
        .format("H:mm:ss")
    );
  };

  useEffect(() => {
    const toggle = async () => {
      if (
        track.url !==
        `https://myradio-segments-scripts.s3.ap-southeast-2.amazonaws.com/1second.mp3`
      ) {
        await setPlaying(true);
        setToastMessage(`Now playing ${track.title}`);
      }
    };
    toggle();
  }, [track]);

  useEffect(() => {
    const savePlaylist = async () => {
      localStorage.setItem("episodes", JSON.stringify(playlist));
    };
    if (initPlaylist === true) {
      savePlaylist();
    }
  }, [playlist]);

  useEffect(() => {
    const getPlaylist = async () => {
      const storedPrefs = await localStorage.getItem("episodes");
      if (typeof storedPrefs === "string") {
        try {
          const saved = JSON.parse(storedPrefs);
          if (saved) {
            setPlaylist(saved);
          } else {
            setPlaylist([]);
          }
        } catch (error) {
          console.log("There was an error with the saved playlist");
          setPlaylist([]);
        }
      } else {
        setPlaylist([]);
      }
      setInitPlaylist(true);
    };
    if (initPlaylist === false) {
      getPlaylist();
    }
  }, []);

  const removeFromPlaylist = async (item) => {
    const after = playlist.filter((t) => t.url !== item.url);
    setPlaylist(after);
  };

  const pickNewTrack = async () => {
    console.log("track Dinished");
    const inPlaylist = await playlist.findIndex(
      (item) => item.url === track.url
    );
    console.log(inPlaylist, "track Dinished");
    if (inPlaylist !== null) {
      const next = inPlaylist + 1;
      if (playlist[next]) {
        console.log(playlist[next], "Here");
        setTrack(playlist[next]);
      } else {
        setPlaying(false);
      }
    } else {
      setPlaying(false);
    }
  };

  return (
    <RadioContext.Provider
      value={{
        ref,
        setTrack,
        track,
        buffering,
        setPlaying,
        updateDuration,
        updateProgress,
        setBuffering,
        playing,
        newSeek,
        updateTime,
        streamPercent,
        streamProgress,
        duration,
        playAudio,
        station,
        stationLoading,
        liveStream,
        setLiveStream,
        userId,
        playlist,
        setPlaylist,
        removeFromPlaylist,
        toastMessage,
        setToastMessage,
        pickNewTrack,
        homePageData,
        setHomePageData,
        homeBanners,
        getSiteBanner,
        getHomeBanner,
        onAir,
        episodes,
        sections,
        loading,
      }}
    >
      {children}
    </RadioContext.Provider>
  );
};
