import React, { useContext, useEffect } from "react";
import AudioPlayer from "./AudioPlayer";
import Footer from "./footer/Footer";
import Seo from "./Seo";
import { SITE_TITLE } from "../utils/Constants";
import { ToastContainer, toast } from "react-toastify";
import { RadioContext } from "../Context";
import { ThemeContext } from "../ThemeContext";

const defaultSeo = {
  title: SITE_TITLE,
  description: "",
};

const Layout = ({
  children,
  seo = defaultSeo,
  article = null,
  route = null,
  pathname = "",
  addType = "site",
}) => {
  const { toastMessage, setToastMessage } = useContext(RadioContext);
  const { theme } = useContext(RadioContext);
  useEffect(() => {
    if (toastMessage) {
      toast(toastMessage);
      setToastMessage(null);
    }
  }, [toastMessage]);
  return (
    <div>
      <div>
        <Seo seo={article} article={{ article }} />
        {/* <ToolBar/>
            <Sides/>
            <Backdrop/> */}
      </div>
      <main className="p-4">{children}</main>
      <Footer />
      <div className="fixed bottom-0 left-0 right-0 lg:hidden p-2 z-40 bg-black">
        <AudioPlayer />
      </div>
      <ToastContainer
        autoClose={2000}
        pauseOnHover
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        position="bottom-center"
      />
    </div>
  );
};

export default Layout;
