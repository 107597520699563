import { useContext, useState, useEffect } from "react";
import Layout from "../components/Layout";
import { API, STATION_SLUG, PageWrapperClass } from "../utils/Constants";
import { RadioContext } from "../Context";
import Loading from "../components/Loading";
import EpisodeRow from "../components/EpisodeRow";
import Banner from "../components/Banner";
import PlayButton from "../components/PlayButton";

export default function Episodes() {
  const { liveStream } = useContext(RadioContext);
  const [episodes, setEpisodes] = useState([]);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [loading, setLoading] = useState(false);

  const thisTrack = {
    ...liveStream,
    title: `Live Stream`,
    link: null,
  };

  useEffect(() => {
    const getEpisodes = async () => {
      if (loading === true && episodes.length === 0) return;
      setLoading(true);
      var req = await fetch(
        `${API}/public/station/${STATION_SLUG}/episodelist?page=${page}&limit=15&only_new=true`
      );
      const results = await req.json();
      console.log(results);
      if (results.data) {
        console.log("Got here");
        setEpisodes(results.data);
        setLimit(results.last_page);
      }
      setLoading(false);
    };
    if (loading === false && episodes.length === 0) getEpisodes();
  });

  const getEpisodes = async () => {
    setPage(page + 1);
    if (loading === true) return;
    setLoading(true);
    var req = await fetch(
      `${API}/public/station/${STATION_SLUG}/episodelist?page=${page}&limit=15&only_new=true`
    );
    const results = await req.json();
    console.log(results);
    if (results.data) {
      console.log("Got here");
      setEpisodes([...episodes, ...results.data]);
    }
    setLoading(false);
  };

  return (
    <Layout
      pathname={`/listen`}
      route={"episodes"}
      article={{
        title: "Listen now",
        description:
          "Hear our live stream or our latest episodes in your browser now.",
      }}
    >
      <div className={PageWrapperClass}>
        <h1 className="page-heading mb-8 xl:mb-12">Listen to Eastside</h1>
        <div className="block md:flex gap-4 mb-4 xl:mb-12 items-center">
          {thisTrack && (
            <PlayButton
              iconClass={"h-24 w-24 xl:h-30 xl:w-30"}
              forceDisplay={true}
              thisTrack={thisTrack}
            />
          )}
          <div>
            <h2 className="mb-2 text-xl xl:text-4xl font-bold">Listen Live!</h2>
            <p>Or choose from a previously recorded episode below.</p>
          </div>
        </div>
        {episodes &&
          episodes.map((episode, i) => (
            <EpisodeRow key={i} episode={episode} />
          ))}
        {limit !== page && (
          <button
            onClick={() => {
              getEpisodes();
            }}
          >
            Load More
          </button>
        )}
        {loading && <Loading />}
        <Banner />
      </div>
    </Layout>
  );
}
