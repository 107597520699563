import { Link } from "react-router-dom";
import moment from "moment";
import Truncate from "react-truncate-html";

export default function PostFeatured({
  post = null,
  wClass = "w-full md:w-1/2",
}) {
  return (
    <div className={`post-tease block mb-4 w-full ${wClass}`}>
      {post && (
        <Link to={`/${post.slug}`}>
          <div
            style={{
              backgroundImage: `url(${
                post.type === "post"
                  ? post._embedded["wp:featuredmedia"][0]?.source_url
                  : null
              })`,
            }}
            className="w-full aspect-video bg-gray-200 bg-opacity-30 flex items-end text-center bg-center bg-cover justify-center text-white mb-4"
          ></div>

          <div className="flex-1 flex-grow">
            <Link to={`/${post.slug}`}>
              <h2
                className="featured-title font-bold  "
                dangerouslySetInnerHTML={{ __html: post.title.rendered }}
              />
            </Link>
            {post._embedded.author[0]?.name && post.date && (
              <p className="text-xs font-light mb-4">
                Posted by: {post._embedded.author[0]?.name} Posted At:{" "}
                {moment(post.date).format("dddd, Do MMMM YYYY, h:mm:ss a")}
              </p>
            )}

            <Truncate
              lines={3}
              className={"text-sm"}
              dangerouslySetInnerHTML={{
                __html: post.excerpt.rendered,
              }}
            />
            {/* 
            <div
              className="entry-content text-xs"
              dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
            /> */}
          </div>
        </Link>
      )}
    </div>
  );
}
