import { Link } from "react-router-dom";
import { buildSectionTrack } from "../utils/Functions";
import { RadioContext } from "../Context";
import { PlayIcon, PauseIcon } from "@heroicons/react/solid";
import { useContext } from "react";
import AddToPlaylistButton from "./AddToPlaylistButton";
import PlayButton from "./PlayButton";

export default function SectionRow({ section }) {
  const { track, playing, playAudio, setTrack, buffering } =
    useContext(RadioContext);
  const thisTrack = buildSectionTrack({ segment: section });
  return (
    <div className="section mb-8">
      <div className="w-full">
        <Link className="w-full  gap-4 flex" to={`/segment/${section.id}`}>
          <div>
            <div
              style={{
                backgroundImage: `url(${
                  section.image ? section.image.url : ""
                })`,
              }}
              className="program-image aspect-square max-w-1/5 bg-gray-200 bg-opacity-30 flex items-center text-center bg-center bg-cover justify-center text-white"
            >
              <PlayButton thisTrack={thisTrack} />
            </div>
          </div>
          <div className="flex-1">
            <div className=" flex gap-2 items-end">
              <div className="flex-1">
                <h3 className="section-heading mb-2">
                  {thisTrack && thisTrack.title}
                </h3>
                <p className="text-sm">Duration: {thisTrack.time}</p>
                <p className="text-sm">Aired: {section.readable}</p>
              </div>
              <div>
                <AddToPlaylistButton divClass="block" thisTrack={thisTrack} />
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
