import { Link } from "react-router-dom";
import { buildEpisodeTrack } from "../utils/Functions";
import { RadioContext } from "../Context";
import { useContext } from "react";
import SectionRow from "./SectionRow";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddToPlaylistButton from "./AddToPlaylistButton";
import PlayButton from "./PlayButton";

export default function EpisodePage({ episode }) {
  const { track, playing, playAudio, setTrack, buffering } =
    useContext(RadioContext);
  const thisTrack = buildEpisodeTrack({ episode });
  return (
    <div className="episode mb-8">
      <div className="flex gap-2 items-center mb-4 mt-4">
        {track && thisTrack && <PlayButton thisTrack={thisTrack} />}
        {track && thisTrack && (
          <AddToPlaylistButton
            buttonText={{
              in: "Added to playlist",
              out: "Add to playlist",
            }}
            thisTrack={thisTrack}
          />
        )}
      </div>
      <div className="w-full">
        <div>
          <div className="grid grid-cols-4 xl:grid-cols-5 gap-4 mb-8">
            <div className="col-span-4 md:col-span-1 xl:col-span-1 mb-4">
              {episode.image ? (
                <img
                  src={episode.image.url}
                  alt={thisTrack.title}
                  className="w-full h-auto"
                />
              ) : episode.program && episode.program.image ? (
                <img
                  src={episode.program.image.url}
                  alt={thisTrack.title}
                  className="w-full h-auto"
                />
              ) : (
                "No Image Uploaded"
              )}
            </div>
            <div className="col-span-4 md:col-span-3 xl:col-span-4 mb-4">
              {episode.program && (
                <p className={"pb-4"}>
                  <Link to={`/program/${episode.program.slug}`} className="hover:underline">
                    Program: {episode.program.name}
                  </Link>
                </p>
              )}
              <p>Duration: {thisTrack.time}</p>
              
              {episode && episode.description ? (
                <p
                  itemProp="description"
                  className=" mb-3 "
                  dangerouslySetInnerHTML={{
                    __html: episode.description
                      ? episode.description.split("\n").join("<br>")
                      : "",
                  }}
                ></p>
              ) : episode.program && episode.program.bio ? (
                <p
                  itemProp="description"
                  className="  mb-3  "
                  dangerouslySetInnerHTML={{
                    __html: episode.program
                      ? episode.program.bio?.split("\n").join("<br>")
                      : "",
                  }}
                ></p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {episode && (
        <Tabs>
          <TabList>
            {episode && episode.logs.length > 0 && <Tab>Playlist</Tab>}
            {episode && episode.sections && episode.sections.length > 0 && (
              <Tab>Segments</Tab>
            )}
          </TabList>

          {episode && episode.logs.length > 0 && (
            <TabPanel>
              <div className="py-4">
                {episode &&
                  episode.logs.map((log, i) => (
                    <div
                      key={`log${i}`}
                      className="border-solid border-gray-800 logs mb-2"
                    >
                      <p className="text-base">
                        <span className="font-bold">{log.title}</span>{" "}
                        {log.artist && (
                          <span className="">by {log.artist}</span>
                        )}{" "}
                        <span className=" text-xs">
                          {log.location && log.location === "nat"
                            ? "(nat)"
                            : log.location && log.location === "int"
                            ? "(int)"
                            : "(loc)"}
                        </span>
                      </p>
                      {log.album && (
                        <p className="text-xs">Release: {log.album}</p>
                      )}
                    </div>
                  ))}
              </div>
            </TabPanel>
          )}
          {episode && episode.sections && episode.sections.length > 0 && (
            <TabPanel>
              <div className="py-4">
                <div>
                  {episode &&
                    episode.sections.length > 0 &&
                    episode.sections.map((section, i) => (
                      <SectionRow section={{ ...section }} key={`${i}s`} />
                    ))}
                </div>
              </div>
            </TabPanel>
          )}
        </Tabs>
      )}
    </div>
  );
}
