import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { RadioContext } from "../../Context";
import { ThemeContext } from "../../ThemeContext";
import AudioPlayer from "../AudioPlayer";
import { Transition } from "@headlessui/react";
import Toggle from "../../ThemeToggle";
import {
  MenuAlt3Icon,
  MenuAlt1Icon,
  XCircleIcon,
  ClipboardListIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/solid";
import PlayButton from "../PlayButton";

export default function Header() {
  const { theme } = useContext(ThemeContext);
  const [isShowing, setIsShowing] = useState(false);
  const { station, playlist, liveStream } = useContext(RadioContext);

  const thisTrack = {
    ...liveStream,
    title: `Live Stream`,
    link: null,
  };

  return (
    <header
      className={`${
        isShowing && "bg-gray-900 h-screen xl:h-auto"
      } fixed top-0 left-0 right-0  z-40 flex-col flex`}
    >
      <div
        id="site-header"
        className={`flex text-white justify-between items-center gap-2 px-4 xl:px-6 ${
          theme === "light" ? "bg-gray-900" : ""
        } dark:bg-gradient-to-b dark:from-gray-900`}
      >
        {station && station.image && (
          <Link to="/" className="font-bold mr-3 xl:mr-12">
            <img
              src={station.image.url}
              alt={station.name}
              className="site-logo"
            />
          </Link>
        )}
        <div className="flex flex-1 justify-end xl:justify-start items-center gap-4">
          <button
            onClick={() => setIsShowing(!isShowing)}
            to="/playlist"
            className="font-bold hidden xl:inline hover:underline"
          >
            {isShowing ? (
              <XCircleIcon className="w-8 h-8 text-white" />
            ) : (
              <MenuAlt1Icon className="w-8 h-8 text-white" />
            )}
          </button>
          {/* <Link
            onClick={() => setIsShowing(false)}
            to="/"
            className="font-bold"
          >
            <HomeIcon className="w-8 h-8 text-white" />
            <span className="sr-only">Home</span>
          </Link> */}
          <Link
            onClick={() => setIsShowing(false)}
            to="/listen"
            className="font-bold hidden md:inline hover:underline"
          >
            Listen Now
          </Link>

          <Link
            onClick={() => setIsShowing(false)}
            to="/programs"
            className="font-bold hidden md:inline hover:underline"
          >
            Our Programs
          </Link>

          <Link
            onClick={() => setIsShowing(false)}
            to="/timetable"
            className="font-bold hidden md:inline hover:underline"
          >
            Timetable
          </Link>

          <PlayButton
            divClass="xl:hidden h-8 w-8"
            iconClass={"h-8 w-8"}
            forceDisplay={true}
            thisTrack={thisTrack}
          />

          <Link
            onClick={() => setIsShowing(false)}
            to="/playlist"
            className="font-bold inline hover:underline xl:hidden relative"
          >
            <ClipboardListIcon className="h-8 w-8 xl:hidden" />{" "}
            <span className="sr-only">My Playlist</span>
            {playlist && playlist.length > 0 && (
              <div className="absolute -mt-1 -mr-2 w-4 h-4 flex items-center justify-center text-xs top-0 right-0 rounded-full aspect-square bg-brand text-white">
                {playlist.length}
              </div>
            )}
          </Link>

          <Link
            onClick={() => setIsShowing(false)}
            to="/donations"
            className="font-bold text-sm flex gap-1 items-center hover:underline xl:hidden relative rounded-lg bg-brand text-white dark:text-white dark:bg-brand py-1 px-2"
          >
            <CurrencyDollarIcon className="h-8 w-8 xl:hidden" />{" "}
            <span>DONATE</span>
          </Link>

          <button
            onClick={() => setIsShowing(!isShowing)}
            className="font-bold xl:hidden hover:underline"
          >
            {isShowing ? (
              <XCircleIcon className="w-8 h-8 text-white" />
            ) : (
              <MenuAlt3Icon className="w-8 h-8 text-white" />
            )}
          </button>

          {/* <Link to="/" className="font-bold hidden xl:inline">
          Segments
        </Link>
        <Link to="/" className="font-bold hidden xl:inline">
          Episodes
        </Link> */}
          {/* <Link to="/" className="font-bold hidden xl:inline">
          Timetable
        </Link>
        <Link to="/" className="font-bold hidden xl:inline">
          About
        </Link> */}
        </div>

        <div className="w-1/3 ml-12 hidden xl:block">
          <AudioPlayer />
        </div>
        <Toggle />
        <Link
          onClick={() => setIsShowing(false)}
          to="/playlist"
          className="font-bold hidden xl:inline hover:underline ml-4 relative"
        >
          <ClipboardListIcon className="h-8 w-8 xl:hidden" />
          <span className="hidden xl:inline">My Playlist</span>{" "}
          {playlist && playlist.length > 0 && (
            <div className="-mt-1 -mr-4 absolute w-4 h-4 flex items-center justify-center text-xs top-0 right-0 rounded-full aspect-square bg-brand text-white">
              {playlist.length}
            </div>
          )}
        </Link>

        <Link
          onClick={() => setIsShowing(false)}
          to="/donations"
          className="font-bold hidden xl:inline hover:underline ml-4 relative text-white bg-brand py-2 px-3 rounded-lg ml-6"
        >
          <ClipboardListIcon className="h-8 w-8 xl:hidden" />{" "}
          <span className="hidden xl:inline">DONATE</span>
        </Link>
      </div>
      <Transition
        show={isShowing}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="w-full bg-gray-700 overflow-y-scroll xl:overflow-y-auto text-gray-200 flex-1 flex-grow xl:flex-grow-0"
      >
        <div className="px-8 py-4 block">
          <Link
            onClick={() => setIsShowing(false)}
            to="how-to-listen"
            className="block xl:text-4xl text-2xl font-bold pb-8"
          >
            HOW TO LISTEN
          </Link>
          <div className="block lg:flex mobileMenu justify-between gap-4 flex-wrap pb-12 xl:px-0">
            <div>
              <h4 className="section-heading mb-2 xl:mb-4">PROGRAMS</h4>
              <ul className={`mb-8 xl:mb-0`}>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/programs"
                    className="font-light   hover:underline"
                  >
                    All Programs
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/timetable"
                    className="font-light hover:underline"
                  >
                    Program Timetable
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/blogs/program-blog"
                    className="font-light   hover:underline"
                  >
                    Program Blogs
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/greatideaforashow"
                    className="font-light   hover:underline"
                  >
                    Great Idea for a Show?
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="section-heading mb-2 xl:mb-4">THE LATEST</h4>
              <ul className={`mb-8 xl:mb-0`}>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/blogs/shoutsistershout/"
                    className="font-light   hover:underline"
                  >
                    Shout Sister Shout
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/posts"
                    className="font-light   hover:underline"
                  >
                    Latest News
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/listen"
                    className="font-light   hover:underline"
                  >
                    Latest Episodes
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="section-heading mb-2 xl:mb-4">PODCASTS</h4>

              <ul className={`mb-8 xl:mb-0`}>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/podcast-series"
                    className="font-light   hover:underline"
                  >
                    All Series
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="section-heading mb-2 xl:mb-4">SUPPORT US</h4>
              <ul className={`mb-8 xl:mb-0`}>
                <li>
                  <a
                    onClick={() => setIsShowing(false)}
                    href="https://support.eastsidefm.org"
                    className="font-light   hover:underline"
                    target="_blank"
                  >
                    Become a Supporter
                  </a>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/donations"
                    className="font-light   hover:underline"
                  >
                    Make a donation
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/bequests"
                    className="font-light   hover:underline"
                  >
                    Leave A Gift In Your Will
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/the-allan-slaight-studio/"
                    className="font-light   hover:underline"
                  >
                    The Allan Slaight Studio
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="section-heading mb-2 xl:mb-4">SPONSORSHIP</h4>
              <ul className={`mb-8 xl:mb-0`}>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/sponsorship"
                    className="font-light   hover:underline"
                  >
                    Sponsorship
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="section-heading mb-2 xl:mb-4">ABOUT US</h4>
              <ul className={`mb-8 xl:mb-0`}>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/aboutus"
                    className="font-light   hover:underline"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsShowing(false)}
                    to="/contact"
                    className="font-light   hover:underline"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Transition>
    </header>
  );
}
